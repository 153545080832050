import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

export default () => {
    const image = useStaticQuery(graphql`
    query {
      insta: file(relativePath: { eq: "instagram.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      facebook: file(relativePath: { eq: "facebook.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      twitter: file(relativePath: { eq: "twitter.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
      <footer class="footer" style={{backgroundColor: 'black'}}>
          <div class="content has-text-centered">
              <div class="columns">
                <div class="column">
                    <div
                        style={{
                            textTransform: `uppercase`,
                            fontSize: `1.5rem`,
                            fontWeight: `700`,
                            marginBottom: `10px`,
                            color: `#fff`
                        }}
                    >
                        Social
                    </div>
                    <div>
                        <a target="_blank"
                            rel="noopener noreferrer" 
                            href="https://www.instagram.com/healthhitters/"
                            style={{
                                display: `flex`,
                                flexDirection: `row`,
                                justifyContent: `center`,
                                marginBottom: `15px`
                            }}
                        >
                            <Img
                                fluid={image.insta.childImageSharp.fluid}
                                alt="Instagram logo"
                                objectFit="contain"
                                style={{
                                    width: `20px`,
                                    height: `20px`
                                }}
                                loading="lazy"
                            />
                            <div
                                style={{
                                    paddingLeft: `10px`,
                                    fontSize: `0.8rem`,
                                    color: `#fff`
                                }}
                            >
                                Instagram
                            </div>
                        </a>
                        <a 
                        href="https://www.facebook.com/healthhitters/?ref=bookmarks"
                        rel="noopener noreferrer"
                        target="_blank" 
                            style={{
                                display: `flex`,
                                flexDirection: `row`,
                                justifyContent: `center`,
                                marginBottom: `15px`,
                                marginRight: `3px`
                            }}
                        >
                            <Img 
                                fluid={image.facebook.childImageSharp.fluid}
                                alt="Facebook logo"
                                objectFit="contain"
                                style={{
                                    width: `20px`,
                                    height: `20px`,
                                }}
                                loading="lazy"
                            />
                            <div
                                style={{
                                    paddingLeft: `10px`,
                                    fontSize: `0.8rem`,
                                    color: `#fff`
                                }}
                            >
                                Facebook
                            </div>
                        </a>
                        <a 
                        href="https://twitter.com/Health_Hitters"
                        rel="noopener noreferrer"
                        target="_blank" 
                            style={{
                                display: `flex`,
                                flexDirection: `row`,
                                justifyContent: `center`,
                                marginRight: `20px`
                            }}
                        >
                            <Img 
                                fluid={image.twitter.childImageSharp.fluid}
                                alt="Twitter logo"
                                objectFit="contain"
                                style={{
                                    width: `20px`,
                                    height: `20px`
                                }}
                                loading="lazy"
                            />
                            <div
                                style={{
                                    paddingLeft: `10px`,
                                    fontSize: `0.8rem`,
                                    color: `#fff`
                                }}
                            >
                                Twitter
                            </div>
                        </a>
                    </div>
                </div>
                <div class="column">
                    <div
                        style={{
                            textTransform: `uppercase`,
                            fontSize: `1.5rem`,
                            fontWeight: `700`,
                            marginBottom: `10px`,
                            color: `#fff`
                        }}
                    >
                        Links
                    </div>
                    <div>
                        <a href="http://healthhitters.com/comingSoon/">
                            <div
                                style={{
                                    fontSize: `0.8rem`,
                                    marginBottom: `15px`,
                                    color: `#fff`
                                }}
                            >
                                About Us
                            </div>
                        </a>
                        <a href="http://healthhitters.com/comingSoon/">
                            <div
                                style={{
                                    fontSize: `0.8rem`,
                                    marginBottom: `15px`,
                                    color: `#fff`
                                }}
                            >
                                Contact
                            </div>
                        </a>
                        <a href="http://healthhitters.com/comingSoon/">
                            <div
                                style={{
                                    fontSize: `0.8rem`,
                                    marginBottom: `15px`,
                                    color: `#fff`
                                }}
                            >
                                Terms & Conditions
                            </div>
                        </a>
                    </div>
                </div>
                <div class="column">
                    <div
                        style={{
                            textTransform: `uppercase`,
                            fontSize: `1.5rem`,
                            fontWeight: `700`,
                            marginBottom: `10px`,
                            color: `#fff`
                        }}
                    >
                        Subscribe to Get Updates!
                    </div>
                    <div class="field has-addons" style={{justifyContent: `center`}}>
                        <div class="control">
                            <input class="input" type="text" placeholder="Enter your email" />
                        </div>
                        <div class="control">
                            <button class="button is-info">
                            Subscribe
                            </button>
                        </div>
                    </div>

                </div>
              </div>
              <div className="bottom">
                <a 
                    href="https://travision.dev/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="trademark"
                    style={{
                        color: `#848d95`,
                        fontSize: `.8em`
                    }}
                >
                    Powered/Designed by TraVision.dev
                </a>
              </div>
          </div>
      </footer>
  )
}