import React from "react"
import PropTypes from "prop-types"

import Header from "./header/header"
import Footer from "./footer/footer"

const Layout = ({ children }) => {

  return (
    <div class="content">
      <Header />
      <div>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
