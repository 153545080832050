import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import Img from "gatsby-image/withIEPolyfill"

export default () => {

  useEffect(() => {
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    );
    if ($navbarBurgers.length > 0) {
      $navbarBurgers.forEach(el => {
        el.addEventListener("click", () => {
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          el.classList.toggle("is-active");
          $target.classList.toggle("is-active");
        });
      });
    }
  });

  const image = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "healthLogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      search: file(relativePath: { eq: "search.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      cart: file(relativePath: { eq: "shoppingCart.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <nav class="navbar" role="navigation" aria-label="main navigation" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
      <div class="navbar-brand">
        <a class="navbar-item" href="http://healthhitters.com">
          <Img
            fluid={image.logo.childImageSharp.fluid}
            alt="Health Hitters logo"
            objectFit="contain"
            style={{
              width: `60px`,
              height: `30px`
            }}
          />
        </a>

        <div role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-end">
          <a class="navbar-item" href="http://healthhitters.com/comingSoon/">
            SHOP
          </a>
          <a class="navbar-item" href="http://healthhitters.com/blogposts/">
            BLOG
          </a>
          <a class="navbar-item" href="http://healthhitters.com/comingSoon/">
            CONTACT
          </a>
          <a class="navbar-item shoppingCart" style={{ paddingLeft: '0px' }} href="http://healthhitters.com/comingSoon/">
            <Img
              fluid={image.cart.childImageSharp.fluid}
              alt="Shopping cart"
              objectFit="contain"
              style={{
                width: `15px`,
                height: `15px`
              }} 
            />
          </a>
        </div>
      </div>

    </nav>
  )
};